import ArticleCardTopics from './constants';
export const FAQ_MOCKS = [
    { title: 'What is copy trading?', text: 'copy trading' },
    {
        title: 'How does copy trading work?', text: `
        Copy trading is a method of investment where individuals automatically replicate the trades executed by experienced traders. 
        Essentially, it allows investors to mimic the trading strategies of successful traders, often in real-time,
        without the need for active involvement in market analysis or decision-making. 
        Through copy trading platforms, users can connect their accounts to those of
        professional traders and mirror their trading activities,
        potentially benefiting from their expertise and generating profits based on their performance.
        This approach is particularly appealing to novice investors seeking to learn from seasoned traders
        and potentially achieve similar levels of success in the financial markets.`,
    },
    { title: 'Is copy trading suitable for beginners?', text: 'trading' },
    { title: 'What are the benefits of copy trading?', text: 'benefits' },
    { title: 'Are there risks associated with copy trading?', text: 'risks' },
    { title: 'How do I choose a trader to copy?', text: 'choose trader' },
];
export const ARTICLES_MOCKS = [
    {
        title: 'Connect an Exchange',
        text: 'Guide on how to connect an exchange',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.EXCHANGE,
    },
    {
        title: 'Start Copy Portfolio',
        text: 'How to choose a place in queue and more',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.COPY_PORTFOLIO,
    },
    {
        title: 'Withdrawal of Profit',
        text: 'What tools can I use to withdraw profits',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.WITHDRAWAL,
    },
    {
        title: 'Connect an Exchange',
        text: 'Guide on how to connect an exchange',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.EXCHANGE,
    },
    {
        title: 'Start Copy Portfolio',
        text: 'How to choose a place in queue and more',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.COPY_PORTFOLIO,
    },
    {
        title: 'Withdrawal of Profit',
        text: 'What tools can I use to withdraw profits',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.WITHDRAWAL,
    },
    {
        title: 'Connect an Exchange',
        text: 'Guide on how to connect an exchange',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.EXCHANGE,
    },
    {
        title: 'Start Copy Portfolio',
        text: 'How to choose a place in queue and more',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.COPY_PORTFOLIO,
    },
    {
        title: 'Withdrawal of Profit',
        text: 'What tools can I use to withdraw profits',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.WITHDRAWAL,
    },
    {
        title: 'Start Copy Portfolio',
        text: 'How to choose a place in queue and more',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.COPY_PORTFOLIO,
    },
    {
        title: 'Withdrawal of Profit',
        text: 'What tools can I use to withdraw profits',
        link: '',
        date: new Date('February 12, 2024'),
        type: ArticleCardTopics.WITHDRAWAL,
    },
];
